import { Dropdown } from 'react-bootstrap';
import { OptionsGreyIcon } from '../../assets/icons';
import './OptionsDropdown.scss';
import { Booking, Option } from '../../types';
import { FC } from 'react';

interface OptionsDropdownProps {
    booking: Booking,
    isRecurring: boolean,
    options: Option[],
    onOptionSelect: (value: string, booking: Booking, recurring: boolean) => void;
}

const OptionsDropdown: FC<OptionsDropdownProps> = ({ options, booking, isRecurring, onOptionSelect }) => {

    return <Dropdown className={"options-dropdown"} drop="end">
        <Dropdown.Toggle className={"options-dropdown-button"}>
            <OptionsGreyIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu className={"options-dropdown-menu"}>
            {options.map((item) =>
                <Dropdown.Item key={item.value}
                    onClick={() => onOptionSelect(item.value, booking, isRecurring)}>{item.name}</Dropdown.Item>
            )}
        </Dropdown.Menu>
    </Dropdown>;
};

export default OptionsDropdown;