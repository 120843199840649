import React, { forwardRef, ForwardedRef, FC } from "react";
import { Card, Button, Tooltip, OverlayTrigger, Accordion } from 'react-bootstrap';
import {
    ArrowBottomBlackIcon,
    AvailableIcon,
    BusyIcon
} from "../../assets/icons";
import './DeskCard.scss';
import { BookingSettings, Desk, DeskStatus, RecurringBookingSettings } from "../../types";
import { formatDeskHours } from "../../utils/deskCardUtils";
import CalendarSmall from "../CalendarSmall/CalendarSmall";
// import Tags from "../Tags/Tags";

interface DeskCardProps {
    desk: Desk,
    bookingOptions: BookingSettings,
    recurringBookingOptions: RecurringBookingSettings,
    added: boolean,
    inactive: boolean,
    status: DeskStatus,
    openCalendar: boolean,
    handleCalendarToggle: (id: string, value: any, toggle: boolean) => void,
    onAddOrRemove: (id: string, action: string) => void;
    date?: Date,
}

const DeskCard = forwardRef((props: DeskCardProps, ref: ForwardedRef<HTMLDivElement>) => {

    interface GroupPathProps {
        flatGroupPath: string;
    }

    const GroupPath: FC<GroupPathProps> = ({ flatGroupPath }) => {
        if (flatGroupPath === "") {
            return <span>Ungrouped</span>;
        } else {
            const groups = flatGroupPath.split(" ->");
            return (
                <>
                    {groups.map((group, index) => (
                        <React.Fragment key={index}>
                            <span>{group}</span>
                            {index < groups.length - 1 && (
                                <>
                                    <br />
                                    <ArrowBottomBlackIcon className={"group-separator"} />
                                    <br />
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </>
            );
        }
    };

    const flatGroupPath = props.desk.groupNamePath;
    const groups = props.desk.groupNamePath.length > 0 ? props.desk.groupNamePath.split(" ->") : [];

    const deskName = props.desk.name;
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            <span className={"tooltip-header"}>{deskName}</span>
            <hr></hr>
            <GroupPath flatGroupPath={flatGroupPath} />
        </Tooltip>
    );

    const getCardHeader: FC<DeskStatus> = ({ available }) => {

        return <>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 50 }}
                overlay={renderTooltip}
            >
                <Card.Header>
                    <>
                        <div className={`desk-name-group-container ${!available || props.inactive ? 'busy' : ''}`}>
                            <span className="desk-name">{props.desk.name}</span>
                            {groups.length > 0 ?
                                <div className={"desk-group-hierarchy"}>
                                    <span>{groups[0]}</span>
                                    {groups.length > 1 ?
                                        <span>{groups[groups.length - 1]}</span> : <></>}
                                </div> : <></>
                            }
                        </div>
                        <div className={"btn-add-container"}>
                            <Button className="btn-round btn-add" hidden={!available || props.inactive} onClick={() => {
                                props.onAddOrRemove(props.desk.id, props.added ? "remove" : "add");
                            }}>
                                <span>{props.added ? "Remove" : "Add"}</span>
                            </Button>
                        </div>
                    </>
                </Card.Header>
            </OverlayTrigger>
        </>;
    };


    interface DeskStatusProps {
        deskStatus: DeskStatus;
    }

    const DeskStatus: FC<DeskStatusProps> = ({ deskStatus }) => {
        let availability = <></>;

        // const timeZone = deskStatus.timeZone ? <><div className="timezone"><span>Time zone: </span><span>{deskStatus.timeZone}</span></div></> : <></>
        const timeZone = <></>;

        if (deskStatus.unknown) {
            return <span className="status-label">Status Unknown</span>;
        }

        if (deskStatus.available) {
            availability = <>
                <span className="status-label"><AvailableIcon /> Available</span>
                <div className="date-available">
                    <span>Working hours: </span>{deskStatus.startTime !== "" ? <><span>{formatDeskHours(deskStatus.startTime, deskStatus.endTime)}</span></> : <></>}
                </div>
                {timeZone}
            </>;
        } else if (deskStatus.outsideWorkingHours) {
            availability = <>
                <span className="status-label"><BusyIcon /> {deskStatus.errorMessage ? deskStatus.errorMessage : "Outside Working Hours"}</span>
                <div className="date-available">
                    {deskStatus.startTime !== "" ? <><span>{formatDeskHours(deskStatus.startTime, deskStatus.endTime)}</span></> : <></>}
                </div>
                {timeZone}
            </>;
        }
        else if (deskStatus.bookedBy) {
            availability = <>
                <span className="status-label"><BusyIcon /> {deskStatus.errorMessage ? deskStatus.errorMessage : deskStatus.bookedBy !== '' ? <>Booked by: {deskStatus.bookedBy}</> : <></>} </span>
                <div className="date-available">
                    <span>Booked from: </span>{deskStatus.bookedFrom !== "" ? <><span>{formatDeskHours(deskStatus.bookedFrom ?? "", deskStatus.bookedUntil ?? "")}</span></> : <></>}
                </div>
                {timeZone}
            </>;
        } else {
            availability = <>
                <span className="status-label"><BusyIcon /> {deskStatus.errorMessage ? deskStatus.errorMessage : deskStatus.bookedBy !== '' ? <>Busy<span>: {deskStatus.bookedBy}</span></> : <></>}</span>
            </>;
        }

        return availability;
    };

    // interface TagsContentProps {
    //     tags: string[];
    // }

    // const TagsContent: React.FC<TagsContentProps> = ({ tags }) => {
    //     let cardHasErrors = false;
    //     let tagsToShow: string[] = [];
    //     let moreTags = false;
    //     let moreTagsCount = 0;

    //     if (tags) {
    //         tagsToShow = cardHasErrors ? tags.slice(0, 2) : tags.slice(0, 4);

    //         moreTags = tags.length > tagsToShow.length;
    //         moreTagsCount = tags.length - tagsToShow.length;
    //     }

    //     return (
    //         <div className={"tag-container"}>
    //             <Tags labels={tagsToShow} />
    //             <span className="more-tags" hidden={!moreTags} onClick={() => { }}>
    //                 + {moreTagsCount} more
    //             </span>
    //         </div>
    //     );
    // };

    const handleAccordionSelect = () => {
        props.handleCalendarToggle(props.desk.id, new Date(), true); // or adjust as needed
    };

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <Card
                key={"cL"}
                className={`${!props.status.available || props.inactive ? "busy" : ""} 
                ${props.recurringBookingOptions !== null ? "recurring" : ""}
                ${props.added ? "added" : ""}`}
                ref={ref}>
                {getCardHeader(props.status)}
                <Card.Body>
                    <DeskStatus deskStatus={props.status} />
                    {/* Unhide to show tags */}
                    {/* <TagsContent tags={props.desk.tags} /> */}
                    {props.recurringBookingOptions !== null ?
                        <Accordion
                            className="calendar-acccordion"
                            alwaysOpen={true}
                            defaultActiveKey={props.openCalendar ? "0" : ""}
                            onSelect={handleAccordionSelect}
                        >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header></Accordion.Header>
                                <Accordion.Body>
                                    {props.openCalendar ?
                                        <CalendarSmall
                                            render={props.openCalendar}
                                            bookingOptions={props.bookingOptions}
                                            recurringBookingOptions={props.recurringBookingOptions}
                                            desk={props.desk}
                                            date={props.date}
                                            onChangeDate={(date) => props.handleCalendarToggle(props.desk.id, date, false)}
                                        />
                                        : <></>}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        : <></>}
                </Card.Body>
            </Card>
        </React.Suspense>

    );
});


export default DeskCard;
