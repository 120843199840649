import "./MyBookings.scss";
import { Button, Dropdown, FormControl, InputGroup, Form, Row, Col } from "react-bootstrap";
import Calendar from "../../components/Calendar/Calendar";
import { useCallback, useContext, useEffect, useState } from "react";
import { ViewportContext } from "../../contexts/ViewportContext";
import MobileCalendarOptions from "./MobileCalendarOptions";
import BookingCard from "../../components/BookingCard/BookingCard";
import { compareByDateTime, formatTime24H, getDateIsoFormatted } from "../../utils/datetimeUtils";
import { IconAdd } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import AppModal from "../../components/AppModal/AppModal";
import { deleteBookingById, fetchAllBookings, updateBooking } from "../../store/bookingsSlice";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "../../components/SwitchButton/SwitchButton";
import { createDateObject } from "../../utils/datetimeUtils"
import { fetchAllDesks, fetchDesksFileteredByGroup } from "../../store/desksSlice";
import { getTimeZone, getWorkHours, validateSettings, getWorkHoursIso, parseRecurrenceRules, getRecurrenceRules } from '../../utils/bookingUtils';
import { fetchIsLicensed, getIsLicensed } from "../../store/licenceSlice";
import { getAuthorizationState } from "../../AppSlice";
import { fetchAllGroups } from "../../store/groupsSlice";
import { fetchProfiles } from "../../store/profilesSlice";
import { getDeskStatus, getRecurringDeskStatus } from "../../utils/deskCardUtils";
import RecurringBookingControls from "../../components/DeskBookingControls/RecurringBookingControls";

function MyBookings() {

    const PORTAL_ADMIN_ROLE = "portal.admin";
    const defaultView = 3;
    const { isMobile } = useContext(ViewportContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [desks, setDesks] = useState([]);
    const [bookings, setBookings] = useState([]);
    const authState = useSelector(getAuthorizationState);

    const [viewMode, setViewMode] = useState(() => {
        const storedValue = sessionStorage.getItem('viewMode');
        return storedValue ? Number(storedValue) : defaultView;
    });

    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [selectedBookingIsRecurring, setSelectedBookingIsRecurring] = useState(false);
    const [selectedBookingResource, setSelectedBookingResource] = useState(null);
    const [selectedBookingProfile, setSelectedBookingProfile] = useState(null);
    const [temporaryBookingValues, setTemporaryBookingValues] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [searchQuery, setSearchQuery] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [isInitialised, setIsInitialised] = useState(false);

    const upcomingBookingsChunkSize = 5;
    const upcomingBookingsShowMoreChunkSize = 20;

    const isLicensed = useSelector(getIsLicensed);

    const refreshDesks = useCallback((groupId) => {
        if (groupId) {
            dispatch(fetchDesksFileteredByGroup({ parentGroupId: groupId, includeBookings: false })).then((r) => {
                if (!r.error) {
                    setDesks(JSON.parse(r.payload).data);
                }
            });
        } else {
            dispatch(fetchAllDesks({ includeBookings: true })).then((r) => {
                if (!r.error) {
                    const deskData = JSON.parse(r.payload).data;
                    dispatch(fetchAllGroups()).then(r => {
                        const groupData = JSON.parse(r.payload).data;
                        dispatch(fetchProfiles()).then((r) => {
                            const profileData = JSON.parse(r.payload).data;
                            const desksWithProfiles = populateDeskProfiles(deskData, profileData, groupData);
                            setDesks(desksWithProfiles);
                        })
                    })
                }
            });
        }
    }, [dispatch]);

    const refreshBookings = useCallback(() => {
        dispatch(fetchAllBookings({ anyUser: authState.roles?.includes(PORTAL_ADMIN_ROLE) })).then(r => {
            if (!r.error) {
                setBookings(JSON.parse(r.payload).data);
            }
        });
    }, [dispatch, authState])

    const populateDeskProfiles = (desks, profiles, groups) => {
        let desksWithProfiles = [...desks];

        const findGroupByIdRecursive = (groupId, groupList) => {
            for (const group of groupList) {
                if (group.id === groupId) {
                    return group;
                }
                if (group.childGroups && group.childGroups.length > 0) {
                    const foundGroup = findGroupByIdRecursive(groupId, group.childGroups);
                    if (foundGroup) {
                        return foundGroup;
                    }
                }
            }
            return null;
        };

        const findProfileForGroup = (groupId) => {
            let profile = profiles.find(p => p.groups && p.groups.includes(groupId));

            if (profile) {
                return profile;
            }

            const group = findGroupByIdRecursive(groupId, groups);

            if (group && group.parentGroupId) {
                return findProfileForGroup(group.parentGroupId);
            }

            return profiles.find(p => p.isDeskDefault);
        };

        desksWithProfiles.forEach(desk => {
            if (!desk.parentGroupId) {
                desk.profile = profiles.find(p => p.isDeskDefault);
            } else {
                desk.profile = findProfileForGroup(desk.parentGroupId);
            }
        });

        return desksWithProfiles;
    };

    const initialise = useCallback(() => {
        refreshDesks();
        refreshBookings();
        if (!isLicensed) {
            dispatch(fetchIsLicensed());
        }
        setIsInitialised(true);
    }, [dispatch, isLicensed, refreshDesks, refreshBookings])

    useEffect(() => {
        if (!isInitialised) {
            initialise();
        }
    }, [isInitialised, initialise])

    const handleChangeViewMode = (value) => {
        setViewMode(value);
        sessionStorage.setItem('viewMode', value.toString());
    };

    const clearSearchQuery = () => setSearchQuery("");

    const handleChangeDate = (value) => {
        setSelectedDate(prevDate => {
            let tempDate = new Date(prevDate);

            if (value === 0) {
                return new Date();
            }

            switch (viewMode) {
                case 1:
                    tempDate.setDate(tempDate.getDate() + value);
                    return new Date(tempDate);
                case 2:
                    tempDate.setDate(tempDate.getDate() + (value * 7));
                    return new Date(tempDate);
                case 3:
                    tempDate.setMonth(tempDate.getMonth() + value);
                    return new Date(tempDate);
                default:
                    return prevDate;
            }
        });
    };

    const handleDeleteBooking = () => {
        dispatch(deleteBookingById({ id: selectedBooking })).then(() => {
            refreshDesks();
            refreshBookings();
        });
        clearSelectedBooking();
        setShowCancellationModal(false);
        setShowEditModal(false);
    }

    const clearSelectedBooking = () => {
        setSelectedBooking(null);
        setSelectedBookingResource(null);
        setSelectedBookingProfile(null);
        setTemporaryBookingValues({});
    }

    const generateBookingSettings = useCallback((booking) => {
        // using Date here converts UTC strings to local datetime
        const startTime = new Date(booking?.startTime);
        const endTime = new Date(booking?.endTime);

        const settings = {
            date: getDateIsoFormatted(startTime),
            startTime: formatTime24H(startTime),
            endTime: formatTime24H(endTime),
            allDay: booking?.allDay,
            recurring: selectedBookingIsRecurring ?
                parseRecurrenceRules(booking?.recurrence[0], getDateIsoFormatted(startTime)) :
                undefined,
        };

        return settings;
    }, [selectedBookingIsRecurring]);

    useEffect(() => {
        if (selectedBooking) {
            // If recurring booking, get values from first recurrence.
            const bookingToEdit = selectedBookingIsRecurring ? bookings.filter((b) => b.masterOccurenceUid === selectedBooking)[0] :
                bookings.filter((b) => b.uid === selectedBooking)[0];

            setTemporaryBookingValues(generateBookingSettings(bookingToEdit));
        }
    }, [selectedBooking, bookings, selectedBookingIsRecurring, generateBookingSettings]);

    useEffect(() => {
        if (bookings.length > 0 && desks.length === 0) {
            refreshDesks();
        }
    }, [bookings, desks, refreshDesks])

    const handleOptionSelect = (value, booking, recurring = false) => {
        const desk = desks.find(d => d.id === bookings.find(b => b.uid === booking.uid).resourceId);
        const profile = desk.profile;

        setSelectedBooking(recurring ? booking.masterOccurenceUid : booking.uid);
        setSelectedBookingIsRecurring(recurring);
        setSelectedBookingResource(desk);
        setSelectedBookingProfile(profile);

        switch (value) {
            case "edit":
                setShowEditModal(true);
                break;
            case "cancel":
                setShowCancellationModal(true);
                break;
            default:
                clearSelectedBooking();
                break;
        }
    };

    const UpcomingBookings = ({ bookings, expanded }) => {

        const upcomingBookings = bookings.filter((booking) => new Date(booking.endTime) > new Date());

        if (upcomingBookings.length === 0)
            return <span>No upcoming bookings.</span>;

        const chunkSize = expanded ? upcomingBookingsShowMoreChunkSize : upcomingBookingsChunkSize;
        const bookingsToDisplay = [...upcomingBookings].sort(compareByDateTime).slice(0, chunkSize);

        const upcomingBookingCards = bookingsToDisplay.map((booking) => {
            return <BookingCard booking={booking} onOptionSelect={handleOptionSelect} key={booking.uid} />
        });

        return <div className={"upcoming-bookings-container"}>{upcomingBookingCards}</div>;
    };

    const SearchResults = ({ bookings, resources }) => {

        const lowerCaseSearchQuery = searchQuery.toLocaleLowerCase();
        if (bookings.length === 0)
            return <span>No bookings to show.</span>;

        const chunkSize = 15;
        const bookingsMatchingQuery = [...bookings].filter((booking) => {
            const resource = resources.filter((r) => r.id === booking.resourceId)[0];
            const groups = resource.groupNamePath.split(" ->");
            return resource.displayName?.toLowerCase().includes(lowerCaseSearchQuery) ||
                groups.filter((group) => group.toLocaleLowerCase().includes(lowerCaseSearchQuery)).length > 0;
        }).slice(0, chunkSize);

        if (bookingsMatchingQuery.length === 0)
            return <span>No bookings found for your search terms.</span>

        const upcomingBookingCards = bookingsMatchingQuery.map((booking) => {
            return <BookingCard booking={booking} onOptionSelect={handleOptionSelect} />
        });

        return <div className={"upcoming-bookings-container"}>{upcomingBookingCards}</div>;
    };

    const bookingResourceOptions = [
        { name: "Desk", value: "desk" }
    ];

    const actionFunction = (value) => {
        switch (value) {
            case "desk":
                navigate("/desks");
                break;
            default:
                break;
        }
    };

    const handleSaveBooking = () => {

        const bookingToEdit = selectedBookingIsRecurring ? bookings.filter((b) => b.masterOccurenceUid === selectedBooking)[0] :
            bookings.filter((b) => b.uid === selectedBooking)[0];

        const originalBookingValues = bookingToEdit;
        let newBookingValues = { ...originalBookingValues };

        let recurrenceRules = "";

        // Check if recurring
        if (selectedBookingIsRecurring) {
            recurrenceRules = getRecurrenceRules(temporaryBookingValues.recurring);
        }

        if (temporaryBookingValues.allDay) {
            // If all day, set duration to maximum available period within specified date.
            const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
            const targetIntervalWeekday = weekdays[new Date(originalBookingValues.startTime).getDay()];
            const desk = desks.filter((desk) => desk.id === originalBookingValues.resourceId)[0];
            const deskWorkingHours = getWorkHours(desk);

            // Convert HH:mm work hours to ISO formatted based on date and resource time zone
            const workHoursIso = getWorkHoursIso(temporaryBookingValues.date, deskWorkingHours[targetIntervalWeekday], getTimeZone(desk));

            newBookingValues = {
                ...newBookingValues,
                startTime: workHoursIso.start,
                endTime: workHoursIso.end,
                allDay: temporaryBookingValues.allDay,
                uid: selectedBooking,
                masterOccurenceUid: "",
                recurrence: [recurrenceRules]
            }
        } else {
            newBookingValues = {
                ...newBookingValues,
                startTime: createDateObject(temporaryBookingValues.date, temporaryBookingValues.startTime).toISOString(),
                endTime: createDateObject(temporaryBookingValues.date, temporaryBookingValues.endTime).toISOString(),
                allDay: temporaryBookingValues.allDay,
                uid: selectedBooking,
                masterOccurenceUid: "",
                recurrence: [recurrenceRules]
            }
        }

        dispatch(updateBooking({ id: selectedBooking, body: newBookingValues })).then(() => {
            refreshDesks();
            refreshBookings();
            setShowEditModal(false);
        })
    }

    const handleChangeBookingValue = (option, value) => {
        switch (option) {
            case "date":
                setTemporaryBookingValues({ ...temporaryBookingValues, date: value });
                break;
            case "startTime":
                setTemporaryBookingValues({ ...temporaryBookingValues, startTime: value });
                break;
            case "endTime":
                setTemporaryBookingValues({ ...temporaryBookingValues, endTime: value });
                break;
            case "allDay":
                setTemporaryBookingValues({ ...temporaryBookingValues, allDay: value });
                break;
            case "recurringStartDate":
                setTemporaryBookingValues({
                    ...temporaryBookingValues, recurring:
                        { ...temporaryBookingValues.recurring, recurringStartDate: value }
                });
                break;
            case "repeat":
                setTemporaryBookingValues({
                    ...temporaryBookingValues, recurring:
                        { ...temporaryBookingValues.recurring, repeat: value }
                });
                break;
            case "frequency":
                setTemporaryBookingValues({
                    ...temporaryBookingValues, recurring:
                        { ...temporaryBookingValues.recurring, frequency: value }
                });
                break;
            case "endConditionType":
                setTemporaryBookingValues({
                    ...temporaryBookingValues, recurring:
                        { ...temporaryBookingValues.recurring, endConditionType: value }
                });
                break;
            case "endConditionValue":
                setTemporaryBookingValues({
                    ...temporaryBookingValues, recurring:
                        { ...temporaryBookingValues.recurring, endConditionValue: value }
                });
                break;
            default:
                break;
        }
    }

    const validateEdit = (booking, desk, profile, settings) => {
        let errorMessages = [];

        errorMessages = errorMessages.concat(validateSettings(settings, profile, selectedBookingIsRecurring));

        if (Object.keys(settings).length > 0) {
            let deskStatus;

            if (selectedBookingIsRecurring) {
                const occurrenceIds = bookings.filter(b => b.masterOccurenceUid === booking).map(o => o.uid);
                deskStatus = getRecurringDeskStatus(settings, settings.recurring, desk, true, [...occurrenceIds, booking]);
            } else {
                deskStatus = getDeskStatus(settings, desk, true, [booking]);
            }

            if (!deskStatus.available) {
                if (deskStatus.errorMessage) {
                    errorMessages.push(deskStatus.errorMessage);
                } else if (deskStatus.outsideWorkingHours) {
                    errorMessages.push("Outside desk working hours.");
                }
                else {
                    errorMessages.push("Desk unavailable.");
                }
            }
        }


        return errorMessages;
    }

    const DesktopPage = () => {
        return (<>
            <div className="my-bookings-page-wrapper main-wrapper">
                <div className={"page-header"}>
                    <div className={"page-title main-page-title"}>
                        <h1>My Bookings</h1>
                        <Dropdown className={"add-booking-dropdown"} drop="end">
                            <Dropdown.Toggle className={"add-booking-dropdown-button btn-round"}>
                                <span><IconAdd /> Add</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={"add-booking-dropdown-menu"}>
                                {bookingResourceOptions.map((item) =>
                                    <Dropdown.Item key={item.value}
                                        onClick={() => actionFunction(item.value)}>{item.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className={"search-box"}>
                        <InputGroup className={searchQuery.length > 0 ? "filled" : ""}>
                            <FormControl
                                aria-describedby="search-group"
                                className="search-group"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search booking"
                            />
                            <Button
                                className="clear-search-button"
                                hidden={searchQuery.length === 0}
                                onClick={clearSearchQuery} >X
                            </Button>
                        </InputGroup>
                    </div>
                </div>
                {searchQuery.length > 0 ? <>
                    <h3>Search Results</h3>
                    <SearchResults bookings={bookings} resources={desks} />
                </> : <>
                    <h3>Upcoming Bookings</h3>
                    <UpcomingBookings bookings={bookings} expanded={expanded} />
                    <div className={"show-more-bookings-container"} hidden={bookings.length < upcomingBookingsChunkSize}>
                        <Button onClick={() => setExpanded(!expanded)}>{expanded ? "LESS" : "MORE"}</Button>
                    </div>
                </>}
                <hr />
                <h3>All Bookings</h3>
                <div className={"page-header calendar-controls"}>
                    <div className={"date-controls"}>
                        <InputGroup>
                            <Button className={"date-controls-prev"} onClick={() => handleChangeDate(-1)}>{"<"}</Button>
                            <Button onClick={() => handleChangeDate(0)}>Today</Button>
                            <Button className={"date-controls-next"} onClick={() => handleChangeDate(1)}>{">"}</Button>
                        </InputGroup>
                    </div>
                    <div className={"date-scale-controls"}>
                        <InputGroup>
                            <Button active={viewMode === 1} onClick={() => handleChangeViewMode(1)}>Day</Button>
                            <Button active={viewMode === 2} onClick={() => handleChangeViewMode(2)}>Week</Button>
                            <Button active={viewMode === 3} onClick={() => handleChangeViewMode(3)}>Month</Button>
                        </InputGroup>
                    </div>
                </div>
                <div className={"page-content"}>
                    <Calendar viewMode={viewMode} bookings={bookings} resources={desks} selectedDate={selectedDate} onOptionSelect={handleOptionSelect} />
                </div>
            </div >

            <AppModal
                show={showCancellationModal}
                size={"sm"}
                header={"Cancel Booking"}
                content={<>
                    {!selectedBookingProfile?.settings.cancel
                        ? <p>This booking cannot be deleted as per its resource's profile settings.</p>
                        : selectedBookingIsRecurring
                            ? <p>Are you sure you want to cancel this recurring booking and <b>all of its past and future occurrences?</b> This action is irreversible.</p>
                            : <p>Are you sure you want to cancel this booking? This action is irreversible.</p>
                    }
                </>}
                footer={<>
                    <Button className={"btn-outline"} onClick={() => setShowCancellationModal(false)}>Go Back</Button>
                    <Button className={"btn-solid"} disabled={!selectedBookingProfile?.settings.cancel} onClick={() => handleDeleteBooking(selectedBooking)}>Confirm</Button>
                </>}
            />

            <AppModal
                show={showEditModal}
                size="xl"
                header={"Edit booking"}
                content={<>
                    <Form className={"edit-form"}>
                        <Row>
                            <Col xs={3}>
                                <Form.Label>Date</Form.Label>
                                <Form.Control type="date" placeholder="Date" onChange={(e) => handleChangeBookingValue("date", e.target.value)}
                                    disabled={selectedBookingIsRecurring} defaultValue={temporaryBookingValues.date} />
                            </Col>
                            <Col xs={2}>
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control type="time" placeholder="Start Time" onChange={(e) => handleChangeBookingValue("startTime", e.target.value)}
                                    disabled={temporaryBookingValues.allDay} defaultValue={temporaryBookingValues.startTime} />
                            </Col>
                            <Col xs={2}>
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="time" placeholder="End Time" onChange={(e) => handleChangeBookingValue("endTime", e.target.value)}
                                    disabled={temporaryBookingValues.allDay} defaultValue={temporaryBookingValues.endTime} />
                            </Col>
                            <Col xs={3} className="toggle-switch-col">
                                <ToggleSwitch changeHandler={() => {
                                    handleChangeBookingValue("allDay", !temporaryBookingValues.allDay);
                                }} isActive={temporaryBookingValues.allDay} />
                                <span className={"all-day-label"}>All Day</span>
                            </Col>
                        </Row>
                        {!selectedBookingIsRecurring || temporaryBookingValues.recurring === undefined ? <></> :
                            <Row>
                                <Col>
                                    <RecurringBookingControls className="compact" setSettings={handleChangeBookingValue} defaultRecurrenceSettings={temporaryBookingValues.recurring} />
                                </Col>
                            </Row>}
                        {validateEdit(selectedBooking, selectedBookingResource, selectedBookingProfile, temporaryBookingValues).map((err) => {
                            return <><span className="error-message">{err}</span><br /></>;
                        })}
                    </Form>
                </>}
                footer={<>
                    <Button className={"btn-outline"} onClick={() => {
                        setShowEditModal(false);
                        clearSelectedBooking();
                    }
                    }>Back</Button>
                    <Button className={"btn-solid"} disabled={validateEdit(selectedBooking, selectedBookingResource, selectedBookingProfile, temporaryBookingValues).length > 0} onClick={() => handleSaveBooking()}>Confirm</Button>
                    <Button className={"btn-outline"} onClick={() => setShowCancellationModal(true)}>Delete</Button>
                </>}
            />
        </>);
    };

    const MobilePage = () => {
        return (<>
            <div className="my-bookings-page-wrapper main-wrapper mobile">
                <div className={"page-header"}>
                    <div className={"page-title"}>
                        <h1>Desk Bookings</h1>
                    </div>
                </div>
                <h3>Upcoming Bookings</h3>
                <hr />
                <h3>All Bookings</h3>
                <MobileCalendarOptions onChangeView={handleChangeViewMode} initialView={defaultView} />
                <Calendar viewMode={viewMode} bookings={bookings} selectedDate={selectedDate} onOptionSelect={handleOptionSelect} />
            </div>
        </>);
    };

    return isMobile ? MobilePage() : DesktopPage();
}

export default MyBookings;